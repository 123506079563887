var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { AlertComponent, CardWithSubmit, CardWithTabs, Icon, InputByType, Marginer, PupleBgRow, TextComponent, KRWFomatToString, StyledFormItem, TableComponent, FormItemLabel, EntireLoader, ColorTag, PhoneNumberNormalizer, ButtonWithPlusCircle, AntPrimaryTextButton, PaymentHistoryTable, SendMessageModal, CustomerRegister, ActionPopup, UpdateTicketModal, CustomerSegmentContainer, CustomerTextButton, BigTitleModal, getReservationColumns, XScrollableTable, UploadButton, useEntireLoader, AntModalFunc, AntPrimaryGhostButton, CustomerMergeModal, PreLineText, FluidRow, AntButton, SALES_CONTROLLER_KEYS, ZIndexer } from 'renderer/components';
import styled from 'styled-components';
import { TICKET_TYPE, TICKET_STATUS } from 'renderer/models';
import { COLORS } from 'renderer/styles';
import { TicketTemplateModel, CustomerPointModel } from 'renderer/models';
import { CustomerQuery, ManagerQuery, PaymentHistoryQuery, TicketQuery, QUERY_CACHE, ScheduleQuery, CustomerRelationQuery } from 'renderer/queries';
import Row from 'antd/lib/row';
import Form from 'antd/lib/form';
import * as dayjs from 'dayjs';
import { DATE_FORMAT, DATE_FORMAT_INCLUDE_TIME } from 'renderer/constants';
import notification from 'antd/lib/notification';
import { get, Lodash, orderBy, setShallow, useWindowSize, isValidDateString, isEmpty, sum, floor, S3Client, truncateString, convertHexToRgbA, isPlussharpMobileAppAndAndroid } from 'renderer/utils';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal';
import { MessageHistoryContent } from 'renderer/containers/app/Message/MessageHistory';
import Empty from 'antd/lib/empty';
import { useSaleModal, useScheduleModal } from 'renderer/stores';
import { Layout } from 'common-ui';
import { IMAGES } from 'renderer/assets';
import { CustomerRelationModal } from '../plussharp/CustomerRelationModal';
import modal from 'antd/lib/modal';
import { StorePictureQuery } from 'renderer/queries';
import Image from 'antd/lib/image';
import { useMeasure } from 'react-use';
import debounce from 'lodash/debounce';
import { Flex } from 'antd/lib';
import { convertHEICToJPG } from 'common-utils/file';
export var ZINDEX_ON_CUSTOMER_DETAIL_MODAL = 1004;
export var CustomerDetailInModal = function (props) {
    var _a, _b;
    var isPC = useWindowSize().isPC;
    var store = QUERY_CACHE.getQueryData(['store']);
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var customerId = props.customerId;
    var customer = CustomerQuery.useCustomerDetail({ id: customerId, storeId: storeId }, { retryOnMount: false, enabled: !!customerId && !!storeId });
    var deleteCustomerMutate = CustomerQuery.useDeleteCustomerMutation();
    var handleCustomerDelete = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                Modal.confirm({
                    cancelText: '아니오',
                    okText: '예',
                    title: '정말 삭제하시겠어요?',
                    zIndex: 9999,
                    onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, deleteCustomerMutate.mutateAsync({ storeId: storeId, id: id })];
                                case 1:
                                    _a.sent();
                                    notification.success({ message: '고객 정보 삭제가 완료되었습니다.' });
                                    props.onClose();
                                    return [2 /*return*/];
                            }
                        });
                    }); }
                });
            }
            catch (error) { }
            return [2 /*return*/];
        });
    }); };
    var _c = React.useState(), drawer = _c[0], setDrawer = _c[1];
    var add = useScheduleModal().add;
    var saleModal = useSaleModal();
    var handleOpenDrawer = function (data) {
        var _a;
        if (data.schedule) {
            add({
                schedule: data.schedule,
                type: 'create'
            });
        }
        else if (data.salesHistory) {
            saleModal.add(__assign(__assign({}, data.salesHistory), { fixedCustomerId: true, id: (_a = data.salesHistory) === null || _a === void 0 ? void 0 : _a.id }));
        }
        else {
            setDrawer(data);
        }
    };
    var handleRegisterClose = function () {
        setDrawer(undefined);
    };
    return (React.createElement(React.Fragment, null,
        customer.isLoading || !customer.data ? (React.createElement(EntireLoader, null)) : customer.error ? (React.createElement(Empty, { description: "\uC0AD\uC81C\uB41C \uACE0\uAC1D\uC785\uB2C8\uB2E4." })) : (React.createElement(CustomerDetailContent, __assign({}, props, { store: store, isPC: isPC, onDelete: handleCustomerDelete, onClickOpenDrawer: handleOpenDrawer, customer: customer.data }))),
        React.createElement(CustomerRegister, { zIndex: ZINDEX_ON_CUSTOMER_DETAIL_MODAL, storeId: storeId, data: drawer === null || drawer === void 0 ? void 0 : drawer.customer, onClose: handleRegisterClose, onDelete: handleCustomerDelete }),
        React.createElement(SendMessageModal, { store: store, target: { customerIds: ((_a = drawer === null || drawer === void 0 ? void 0 : drawer.message) === null || _a === void 0 ? void 0 : _a.customerId) ? [Number((_b = drawer === null || drawer === void 0 ? void 0 : drawer.message) === null || _b === void 0 ? void 0 : _b.customerId)] : [] }, onClose: handleRegisterClose, visible: !!(drawer === null || drawer === void 0 ? void 0 : drawer.message), trigger: React.createElement("div", null) })));
};
var CustomerInformationContent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var customer = props.customer;
    var managers = ManagerQuery.getManagersInCache();
    var usingItems = [
        {
            key: 'createdAt',
            label: '최초 등록일',
            data: customer.createdAt ? dayjs(customer.createdAt).format(DATE_FORMAT) : ''
        },
        {
            key: 'lastVisitedAt',
            label: '최근 방문일',
            data: ((_a = customer.meta) === null || _a === void 0 ? void 0 : _a.lastVisitedAt)
                ? "".concat(dayjs((_b = customer.meta) === null || _b === void 0 ? void 0 : _b.lastVisitedAt).format(DATE_FORMAT), " (").concat(dayjs().diff((_c = customer.meta) === null || _c === void 0 ? void 0 : _c.lastVisitedAt, 'day'), "\uC77C \uACBD\uACFC)")
                : ''
        },
        { key: 'numberOfUsing', label: '방문 횟수', data: "".concat(((_d = customer.meta) === null || _d === void 0 ? void 0 : _d.numberOfUsing) || 0, "\uD68C") },
        { key: 'numberOfNoshow', label: '노쇼 횟수', data: "".concat(((_e = customer.meta) === null || _e === void 0 ? void 0 : _e.numberOfNoshow) || 0, "\uD68C") },
        { key: 'totalSales', label: '실 매출액', data: KRWFomatToString({ value: customer.totalSales }) },
        { key: 'customerPoint', label: '적립포인트', data: KRWFomatToString({ value: customer.customerPoint }) },
        { key: 'unpaid', label: '미수금', data: KRWFomatToString({ value: customer.unpaid }) },
        {
            key: 'averageSales',
            label: '평균 실매출액',
            data: KRWFomatToString({ value: floor(customer.totalSales / (((_f = customer === null || customer === void 0 ? void 0 : customer.meta) === null || _f === void 0 ? void 0 : _f.numberOfUsing) || 1)) || 0 })
        }
    ];
    var informationItems = (_g = props.store.customFields) === null || _g === void 0 ? void 0 : _g.customers.map(function (item) {
        var _a, _b, _c, _d, _e, _f;
        if (!item.isDefault) {
            var value = get(props.customer, "data.custom.".concat(item.key));
            return {
                label: item.label,
                data: isValidDateString(value) ? dayjs(value).format(DATE_FORMAT) : value
            };
        }
        if (item.key === 'address') {
            return {
                label: '주소',
                data: "".concat(((_a = customer.address) === null || _a === void 0 ? void 0 : _a.address) || '', " ").concat(((_b = customer.address) === null || _b === void 0 ? void 0 : _b.detailAddress) || '')
            };
        }
        if (item.key === 'gender') {
            return {
                label: '성별',
                data: customer.genderToText() || '-'
            };
        }
        if (item.key === 'birth') {
            return {
                label: '생일',
                data: customer.birth ? dayjs(customer.birth).format(DATE_FORMAT) : '-'
            };
        }
        if (item.key === 'phone') {
            return {
                label: '연락처',
                data: customer.phoneToString
                    ? "".concat(customer.phoneToString, " (\uBB38\uC790\uC218\uC2E0 : ").concat(!((_c = customer.data) === null || _c === void 0 ? void 0 : _c.rejectReceive) ? '수신' : '거부', ")")
                    : ''
            };
        }
        if (item.key === 'customerGroupId') {
            return { label: '등급', data: ((_d = customer.customerGroup) === null || _d === void 0 ? void 0 : _d.name) || '-' };
        }
        if (item.key === 'customerTags') {
            return {
                label: '태그',
                data: (_e = customer.tags) === null || _e === void 0 ? void 0 : _e.map(function (tag) { return React.createElement(ColorTag, { color: tag.color, children: tag.name, key: tag.id }) || '-'; })
            };
        }
        if (item.key === 'managerId') {
            return { label: '담당자', data: ((_f = managers === null || managers === void 0 ? void 0 : managers.find(function (item) { return item.id === customer.managerId; })) === null || _f === void 0 ? void 0 : _f.displayName) || '-' };
        }
        if (item.key === 'customerRelations') {
            return null;
        }
        if (item.key === 'customerNumber') {
            return {
                label: '고객번호',
                data: customer.customerNumber || '-'
            };
        }
        return {
            label: item.label,
            data: get(props.customer, item.key) || get(props.customer, "data.custom.".concat(item.key))
        };
    }).filter(Boolean);
    var _m = useWindowSize(), isPC = _m.isPC, width = _m.width;
    var colSpan = isPC ? 12 : 24;
    return (React.createElement(CustomerInformationContentWrapper, null,
        React.createElement(Col, { span: colSpan },
            React.createElement(InformationCard, { title: "\uAE30\uBCF8\uC815\uBCF4", rightAction: React.createElement(AntPrimaryTextButton, { children: "\uC218\uC815", icon: React.createElement(Icon, { name: "edit3" }), onClick: function () { return props.onClickOpenDrawer({ customer: customer }); } }), items: informationItems })),
        React.createElement(Col, { span: colSpan },
            React.createElement(InformationCard, { title: "\uC774\uC6A9\uC815\uBCF4", items: usingItems }),
            React.createElement(TicketList, { width: width, isPC: isPC, customer: customer, type: "prepaid", tickets: (_h = customer.tickets) === null || _h === void 0 ? void 0 : _h.filter(function (item) { return item.type === TicketTemplateModel.TICKET_TYPE.CHARGING_MONEY; }) }),
            React.createElement(TicketList, { isShared: true, width: width, isPC: isPC, customer: customer, type: "prepaid", tickets: ((_j = customer.sharedTickets) === null || _j === void 0 ? void 0 : _j.filter(function (item) { return item.type === TicketTemplateModel.TICKET_TYPE.CHARGING_MONEY; })) || [] }),
            React.createElement(TicketList, { width: width, isPC: isPC, customer: customer, type: "ticket", tickets: (_k = customer.tickets) === null || _k === void 0 ? void 0 : _k.filter(function (item) { return item.type !== TicketTemplateModel.TICKET_TYPE.CHARGING_MONEY; }) }),
            React.createElement(TicketList, { isShared: true, width: width, isPC: isPC, customer: customer, type: "ticket", tickets: ((_l = customer.sharedTickets) === null || _l === void 0 ? void 0 : _l.filter(function (item) { return item.type !== TicketTemplateModel.TICKET_TYPE.CHARGING_MONEY; })) || [] }))));
};
var TicketList = function (props) {
    var _a, _b;
    var title = props.type === 'prepaid'
        ? "".concat(props.isShared ? '공유 선불액' : '잔여 선불액', " : ").concat(KRWFomatToString({
            value: props.isShared
                ? sum((_a = props.tickets) === null || _a === void 0 ? void 0 : _a.map(function (item) { var _a; return ((_a = item.data) === null || _a === void 0 ? void 0 : _a.remain) || 0; })) || 0
                : props.customer.prepaidPoint || 0
        }))
        : "".concat(props.isShared ? '공유 정기권' : '잔여 정기권', " : ").concat(props.tickets.length || 0, "\uC7A5");
    var _c = React.useState(), selectedTicketId = _c[0], setSelectedTicketId = _c[1];
    var selectedTicket = props.tickets.find(function (item) { return item.id === selectedTicketId; });
    return (React.createElement("div", null,
        React.createElement(TicketPopup, { isPC: props.isPC, width: props.width, customer: props.customer, type: props.type, onClose: function () { return setSelectedTicketId(null); }, selectedTicket: selectedTicket }),
        React.createElement(TextComponent, { marginTop: 30, children: title, type: "headline3", marginBottom: 10 }), (_b = props.tickets) === null || _b === void 0 ? void 0 :
        _b.map(function (item) {
            var _a, _b, _c, _d, _e;
            var isExpired = item.isExpired;
            var description = "".concat(item.type === TicketTemplateModel.TICKET_TYPE.CHARGING_MONEY
                ? KRWFomatToString({ value: ((_a = item.data) === null || _a === void 0 ? void 0 : _a.remain) || 0 })
                : item.type === TicketTemplateModel.TICKET_TYPE.COUNT
                    ? "".concat((_b = item.data) === null || _b === void 0 ? void 0 : _b.remain, "\uD68C")
                    : "".concat(dayjs((_c = item.data) === null || _c === void 0 ? void 0 : _c.startedAt).format(DATE_FORMAT), "~").concat(dayjs((_d = item.data) === null || _d === void 0 ? void 0 : _d.expiredAt).format(DATE_FORMAT)), "(\uB9CC\uB8CC ").concat(item.expiredAt ? dayjs(item.expiredAt).format(DATE_FORMAT) : '무제한', ")");
            var relation = props.isShared
                ? (_e = props.customer) === null || _e === void 0 ? void 0 : _e.customerRelations.find(function (relation) { return relation.otherCustomerId === item.customerId || relation.mainCustomerId === item.customerId; })
                : null;
            var shareData = relation
                ? "".concat(relation.getCustomerName(Number(props.customer.id)), "(").concat(relation.getRelationName(Number(props.customer.id)), ")")
                : "";
            var ticketName = props.type === 'prepaid'
                ? "".concat(item.name, " : ")
                : "[".concat(TicketTemplateModel.getTypeToString(item.type), "] ").concat(item.name, " : ");
            return (React.createElement(TicketItemRow, { onClick: function () { return !props.isShared && setSelectedTicketId(Number(item.id)); }, key: item.id },
                React.createElement(TextComponent, { type: "caption1", children: "".concat(shareData ? "".concat(shareData, "-") : "").concat(ticketName) }),
                React.createElement(TextComponent, { marginLeft: 5, type: "caption1", color: isExpired ? COLORS.danger : COLORS.info, children: description })));
        })));
};
var TicketPopup = function (props) {
    var type = props.type, selectedTicket = props.selectedTicket, onClose = props.onClose;
    return (React.createElement(BigTitleModal, { centered: true, open: !!selectedTicket, zIndex: ZINDEX_ON_CUSTOMER_DETAIL_MODAL, onCancel: props.onClose, footer: null, title: props.type === 'prepaid' ? '선불액 내역' : '정기권 내역', width: props.isPC ? 800 : props.width, children: selectedTicket && (React.createElement(TicketHistoryTable, { id: "TICKET_POPUP_TABLE_".concat(props.type), ticket: selectedTicket, customer: props.customer, storeId: props.customer.storeId })) }));
};
var TicketItemRow = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 4px;\n  cursor: pointer;\n"], ["\n  margin-bottom: 4px;\n  cursor: pointer;\n"])));
var CustomerInformationContentWrapper = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  & > .ant-col {\n    padding: 25px;\n\n    &:first-child {\n      border-right: 1px solid ", ";\n    }\n  }\n"], ["\n  height: 100%;\n  & > .ant-col {\n    padding: 25px;\n\n    &:first-child {\n      border-right: 1px solid ", ";\n    }\n  }\n"])), COLORS.gray6);
export var CustomerDetailContent = function (props) {
    var _a;
    var customer = props.customer;
    var defaultData = { customerId: Number(customer.id), customer: { id: Number(customer.id) } };
    return (React.createElement(CustomerDetailContentWrapper, null,
        React.createElement(CustomerDetailHeader, null,
            React.createElement(Row, { justify: "space-between", align: "top", wrap: false, gutter: 4 },
                React.createElement(Col, { flex: 1 },
                    React.createElement(TextComponent, { children: "".concat(props.isPC ? customer === null || customer === void 0 ? void 0 : customer.name : truncateString(customer.name, 72), " ").concat(customer.phone ? "(".concat(customer.phoneToString, ")") : ''), type: "subtitle1" })),
                !props.hideControlButtons && (React.createElement(Col, null,
                    React.createElement(Button, { onClick: function () { return props.onDelete(Number(customer.id)); }, icon: React.createElement(Icon, { name: "trash", color: COLORS.primary1 }), style: { border: "1px solid ".concat(COLORS.primary1) } })))),
            React.createElement(Row, { gutter: 8, justify: "space-between", align: "stretch", wrap: !props.isPC },
                React.createElement(Col, { span: 24 },
                    React.createElement(CustomerSegmentContainer, { items: CustomerQuery.getCustomerSegments(customer) }),
                    React.createElement(PreLineText, { marginTop: 5, children: "\uBA54\uBAA8 : ".concat(customer.memo || ''), type: "body2", "$height": props.isPC ? 300 : 100 }))),
            React.createElement(Marginer, { height: 10 }),
            React.createElement(Row, { gutter: 4, justify: 'end', align: "bottom", wrap: false },
                !props.hideControlButtons && (React.createElement(Col, null,
                    React.createElement(StyledHeaderButton, { children: "\uC608\uC57D\uB4F1\uB85D", type: "primary", onClick: function () { return props.onClickOpenDrawer({ schedule: defaultData }); } }))),
                !props.hideControlButtons && (React.createElement(Col, null,
                    React.createElement(ActionPopup, { trigger: React.createElement(StyledHeaderButton, { children: "\uB9E4\uCD9C\uB4F1\uB85D", type: "primary" }), actions: [
                            {
                                label: '상품 매출등록',
                                onClick: function () {
                                    return props.onClickOpenDrawer({
                                        salesHistory: {
                                            type: 'product',
                                            data: defaultData
                                        }
                                    });
                                }
                            },
                            {
                                label: '선불액∙정기권 매출등록',
                                onClick: function () {
                                    return props.onClickOpenDrawer({
                                        salesHistory: {
                                            type: 'ticket',
                                            data: defaultData
                                        }
                                    });
                                }
                            }
                        ] }))),
                React.createElement(Col, null,
                    React.createElement(StyledHeaderButton, { children: "\uBB38\uC790\uBC1C\uC1A1", type: "primary", disabled: !customer.availableReceive, onClick: function () { return props.onClickOpenDrawer({ message: defaultData }); } })),
                !props.isPC && (React.createElement(Col, null,
                    React.createElement(StyledHeaderButton, { disabled: !customer.phone || ((_a = customer.phoneToString) === null || _a === void 0 ? void 0 : _a.includes('***')), children: React.createElement("a", { href: "tel:".concat(customer.phone) },
                            React.createElement(Icon, { name: "phone", color: !customer.phone ? COLORS.gray6 : COLORS.white, strokeWidth: 2.5 })), type: "primary" }))))),
        React.createElement(CustomerHistoryTabs, { customer: customer, store: props.store, defaultActiveKey: props.defaultActiveKey, onClickOpenDrawer: props.onClickOpenDrawer })));
};
var CustomerDetailContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"])));
var StyledHeaderButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 150px;\n"], ["\n  max-width: 150px;\n"])));
var CustomerDetailHeader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 20px;\n  border-bottom: 1px solid ", ";\n"], ["\n  padding: 20px;\n  border-bottom: 1px solid ", ";\n"])), COLORS.gray6);
export var CustomerHistoryTabs = function (props) {
    var _a, _b, _c;
    var tabList = [
        { key: 'info', tab: '정보' },
        { key: 'sales', tab: '매출' },
        { key: 'reservations', tab: '예약' },
        // { key: 'product', tab: '상품이용 내역' },
        { key: 'prepaidTickets', tab: '선불액' },
        { key: 'tickets', tab: '정기권' },
        { key: 'customerPointHistory', tab: '포인트' },
        // { key: 'ticket', tab: '보유정기권 관리' },
        // { key: 'prepaidPoint', tab: '선불충전액 내역' },
        // { key: 'customerPoint', tab: '적립포인트 내역' },
        { key: 'unpaid', tab: '미수금' },
        { key: 'messages', tab: '문자' },
        { key: 'customerRelations', tab: '고객연결' },
        { key: 'pictures', tab: '사진' }
    ];
    var store = props.store;
    var storeId = store.id;
    var _d = React.useState(props.defaultActiveKey || 'info'), tabKey = _d[0], setTabKey = _d[1];
    var _e = useWindowSize(), isPC = _e.isPC, height = _e.height;
    var children = {
        info: (React.createElement(CustomerInformationContent, { store: props.store, customer: props.customer, onClickOpenDrawer: props.onClickOpenDrawer })),
        sales: (React.createElement(TabWrapper, null,
            React.createElement(PupleBgRow, null,
                React.createElement(TextComponent, { children: "\uC2E4 \uB9E4\uCD9C\uC561 : ", color: COLORS.gray1, type: "body1", marginRight: 10 }),
                React.createElement(TextComponent, { children: KRWFomatToString({ value: props.customer.totalSales }), color: COLORS.primary1, type: "body1" })),
            React.createElement(Marginer, { height: 35 }),
            React.createElement(PaymentHistoryTable, { key: "all", tableId: "ALL_PAYMENT_TABLE_IN_CUSTOMER", params: {
                    customerId: Number(props.customer.id),
                    storeId: storeId
                }, columns: getColumnKeys('all') }))),
        reservations: React.createElement(CustomerReservationTable, { customer: props.customer }),
        tickets: (React.createElement(TabWrapper, null,
            React.createElement(TicketTable, { key: "tickets", type: "others", hideControlButtons: props.hideControlButtons, onClickOpenDrawer: props.onClickOpenDrawer, customer: props.customer, storeId: storeId, id: "CUSTOMER_TICKETS_TABLE" }))),
        prepaidTickets: (React.createElement(TabWrapper, null,
            React.createElement(TicketTable, { hideControlButtons: props.hideControlButtons, key: "prepaidTickets", type: "prepaid", onClickOpenDrawer: props.onClickOpenDrawer, customer: props.customer, storeId: storeId, id: "CUSTOMER_PREPAID_TABLE" }))),
        customerPointHistory: (React.createElement(TabWrapper, null,
            React.createElement(TicketHistoryTable, { id: "CUSTOMER_POINT_TABLE", customer: props.customer, storeId: storeId }))),
        unpaid: (React.createElement(TabWrapper, null,
            React.createElement(PaymentHistoryTable, { disabledActions: true, params: {
                    customerId: Number(props.customer.id),
                    storeId: storeId,
                    unpaid: 1
                }, key: "unpaid", tableId: "CUSTOMER_UNPAID_TABLE", columns: getColumnKeys('unpaid') }))),
        messages: (React.createElement(TabWrapper, null,
            React.createElement(MessageHistoryContent, { customerId: Number((_a = props.customer) === null || _a === void 0 ? void 0 : _a.id) }))),
        customerRelations: (React.createElement(TabWrapper, null,
            React.createElement(CustomerRelationTab, { customer: props.customer, storeId: (_b = props.customer) === null || _b === void 0 ? void 0 : _b.storeId }))),
        pictures: (React.createElement(TabWrapper, null,
            React.createElement(PicturesTab, { customer: props.customer, storeId: (_c = props.customer) === null || _c === void 0 ? void 0 : _c.storeId, isPC: isPC })))
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledCardWithTabs, { bordered: false, tabList: tabList, children: children, onTabChange: function (tab) { return setTabKey(tab); }, activeTabKey: tabKey })));
};
var StyledCardWithTabs = styled(CardWithTabs)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  border: solid 0px var(--gray-6);\n  & .ant-card-body {\n    padding: 0px;\n    border-top: solid 1px var(--gray-6) !important;\n    flex: 1;\n  }\n  & .ant-card-head {\n    border-bottom: solid 1px var(--gray-6) !important;\n    height: 40px;\n    max-height: 40px;\n  }\n  & .ant-card {\n    border: solid 0px var(--gray-6);\n    border-top: solid 1px var(--gray-6) !important;\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n  }\n  & .ant-tabs-nav {\n    border-bottom: solid 1px var(--gray-6) !important;\n  }\n"], ["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  border: solid 0px var(--gray-6);\n  & .ant-card-body {\n    padding: 0px;\n    border-top: solid 1px var(--gray-6) !important;\n    flex: 1;\n  }\n  & .ant-card-head {\n    border-bottom: solid 1px var(--gray-6) !important;\n    height: 40px;\n    max-height: 40px;\n  }\n  & .ant-card {\n    border: solid 0px var(--gray-6);\n    border-top: solid 1px var(--gray-6) !important;\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n  }\n  & .ant-tabs-nav {\n    border-bottom: solid 1px var(--gray-6) !important;\n  }\n"])));
var TabWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 25px;\n  height: calc(100% - 50px);\n"], ["\n  padding: 25px;\n  height: calc(100% - 50px);\n"])));
export var TicketHistoryTable = function (props) {
    var _a;
    var isCustomerPoint = !props.ticket;
    var customerId = props.customer.id;
    var ticket = props.ticket;
    var storeId = props.storeId;
    var columns = [
        {
            title: '일시',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: function (createdAt) { return dayjs(createdAt).format(DATE_FORMAT_INCLUDE_TIME); }
        },
        {
            title: '내역',
            dataIndex: 'type',
            key: 'type',
            render: function (_, row) { return row.description; }
        },
        {
            title: '유형',
            dataIndex: 'type',
            key: 'type',
            render: function (_, row) { return React.createElement(TextComponent, null, row.typeToString || row.getTypeToString()); }
        },
        {
            title: '증감',
            dataIndex: 'change',
            key: 'change',
            render: function (_, row) { return (React.createElement(TextComponent, { color: row.changeString ? (row.isPlus ? COLORS.info : COLORS.danger) : COLORS.black }, row.changeString || '-')); }
        },
        isCustomerPoint && {
            title: '유효기간',
            dataIndex: 'expiredAt',
            key: 'expiredAt',
            render: function (expiredAt, row) {
                return row.type === CustomerPointModel.POINT_TYPE.CREATED && (React.createElement(UnderlineButton, { disabled: row.isCancel || dayjs().isAfter(row.expiredAt), onClick: function () { return handleClick({ customerPoint: row }); }, type: "link" }, expiredAt ? dayjs(expiredAt).format(DATE_FORMAT) : '무제한'));
            }
        }
    ].filter(Boolean);
    var _b = isCustomerPoint
        ? PaymentHistoryQuery.useCustomerPointHistoryPager({
            page: 1,
            limit: 10,
            storeId: storeId,
            customerId: customerId
        }, { enabled: !!storeId })
        : TicketQuery.useTicketHistory({ page: 1, limit: 10, storeId: storeId, ticketId: Number(props.ticket.id) }), pagerResult = _b.pagerResult, getPaginateObject = _b.getPaginateObject, setNewPagerOptions = _b.setNewPagerOptions;
    var _c = React.useState(), modal = _c[0], setModal = _c[1];
    var handleClick = function (data) {
        setModal(data);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PopupContentHeader, null,
            props.shownCustomerName && React.createElement(CustomerTextButton, { customer: props.customer, type: "headline3", marginBottom: 10 }),
            isCustomerPoint && (React.createElement(Row, { justify: "space-between", align: "middle", style: { marginBottom: '10px' } },
                React.createElement(TextComponent, { type: "headline3", marginBottom: 5, children: "\uC794\uC5EC \uD3EC\uC778\uD2B8 : ".concat(KRWFomatToString({ value: ((_a = props.customer) === null || _a === void 0 ? void 0 : _a.customerPoint) || 0 })) }),
                React.createElement(Col, null,
                    React.createElement(Row, null,
                        React.createElement(ButtonWithPlusCircle, { children: "\uD3EC\uC778\uD2B8 \uCC28\uAC10", isMinus: true, onClick: function () {
                                var _a;
                                return handleClick({
                                    customerPoint: {
                                        type: CustomerPointModel.POINT_TYPE.USED,
                                        latestRemain: (_a = props.customer) === null || _a === void 0 ? void 0 : _a.customerPoint,
                                        storeId: storeId,
                                        customerId: customerId
                                    }
                                });
                            } }),
                        React.createElement(ButtonWithPlusCircle, { children: "\uD3EC\uC778\uD2B8 \uCDA9\uC804", onClick: function () {
                                var _a;
                                return handleClick({
                                    customerPoint: {
                                        type: CustomerPointModel.POINT_TYPE.CREATED,
                                        latestRemain: (_a = props.customer) === null || _a === void 0 ? void 0 : _a.customerPoint,
                                        storeId: storeId,
                                        customerId: customerId
                                    }
                                });
                            } }))))),
            ticket && (React.createElement(React.Fragment, null,
                React.createElement(Row, { justify: "space-between", align: "top", style: { width: '100%' } },
                    React.createElement(TextComponent, { type: "headline3", marginBottom: 5, children: "".concat(ticket.name) }),
                    React.createElement(ButtonWithPlusCircle, { children: ticket.type === TICKET_TYPE.CHARGING_MONEY ? '선불액 조정' : '정기권 조정', onClick: function () {
                            return handleClick({
                                ticket: ticket
                            });
                        } })),
                React.createElement(Row, null,
                    React.createElement(TextComponent, { marginBottom: 10, marginRight: 15, children: "".concat(ticket.getTicketDataToString && ticket.getTicketDataToString(true)) }),
                    React.createElement(TextComponent, { marginBottom: 10, children: "\uC720\uD6A8\uAE30\uAC04: ".concat(ticket.expiredAt ? dayjs(ticket.expiredAt).format(DATE_FORMAT) : '무제한') }))))),
        React.createElement(XScrollableTable, { columns: columns, bordered: false, rowKey: function (row) { return row.id; }, dataSource: pagerResult.items, pagination: getPaginateObject(), loading: false, id: props.id, resizable: {
                additionalColumnWidth: 0
            } }),
        modal && React.createElement(UpdateTicketModal, { data: modal, onCancel: function () { return setModal(undefined); } })));
};
var PopupContentHeader = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 15px;\n"], ["\n  padding: 15px;\n"])));
var UnderlineButton = styled(Button)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  & > span {\n    text-decoration: underline;\n    color: ", ";\n  }\n"], ["\n  & > span {\n    text-decoration: underline;\n    color: ", ";\n  }\n"])), function (props) { return (props.disabled ? COLORS.gray6 : COLORS.black); });
export var CustomerPointTable = function (props) {
    var customerId = props.customer.id;
    var storeId = props.storeId;
    var columns = [
        {
            title: '일시',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: function (createdAt) { return dayjs(createdAt).format(DATE_FORMAT_INCLUDE_TIME); }
        },
        {
            title: '유형',
            dataIndex: 'type',
            key: 'type',
            render: function (_, row) { return row.typeToString; }
        },
        {
            title: '금액',
            dataIndex: 'change',
            key: 'change',
            render: function (_, row) { return row.changeString; }
        },
        {
            title: '소멸예정일',
            dataIndex: 'expiredAt',
            key: 'expiredAt',
            render: function (expiredAt) { return expiredAt && dayjs(expiredAt).format(DATE_FORMAT); }
        }
    ];
    var _a = PaymentHistoryQuery.useCustomerPointHistoryPager({
        page: 1,
        limit: 10,
        storeId: storeId,
        customerId: customerId
    }, { enabled: !!storeId }), pagerResult = _a.pagerResult, getPaginateObject = _a.getPaginateObject, setNewPagerOptions = _a.setNewPagerOptions;
    var willExpiredPoint = CustomerQuery.useWillExpiredPoint({
        storeId: storeId,
        customerId: Number(customerId),
        type: 'customerPoint'
    }, { enabled: !!storeId });
    return (React.createElement(React.Fragment, null,
        React.createElement(PupleBgRow, null,
            React.createElement(Col, { span: 8 },
                React.createElement(Row, { wrap: true },
                    React.createElement(TextComponent, { children: "\uC801\uB9BD \uD3EC\uC778\uD2B8 : ", color: COLORS.gray1, type: "body1", marginRight: 10 }),
                    React.createElement(TextComponent, { children: KRWFomatToString({ value: props.customer.customerPoint }), color: COLORS.primary1, type: "body1" }))),
            React.createElement(Col, null, willExpiredPoint.data ? (React.createElement(TextComponent, { children: "7\uC77C \uC774\uB0B4 \uC18C\uBA78 \uC608\uC815 \uD3EC\uC778\uD2B8: ".concat(KRWFomatToString({ value: willExpiredPoint.data })), color: COLORS.gray1, type: "body1" })) : (React.createElement("div", null)))),
        React.createElement(Marginer, { height: 20 }),
        React.createElement(TableComponent, { columns: columns, rowKey: function (row) { return row.id; }, dataSource: pagerResult.items, pagination: getPaginateObject(), loading: false, scroll: props.scroll })));
};
export var CustomerReservationTable = function (props) {
    var _a = props.customer, id = _a.id, storeId = _a.storeId;
    var _b = ScheduleQuery.useSchedulesByCustomerId({
        page: 1,
        limit: 10,
        storeId: storeId,
        customerId: id
    }, { enabled: !!(id && storeId) }), pagerResult = _b.pagerResult, getPaginateObject = _b.getPaginateObject;
    var dataSource = orderBy(pagerResult.items || [], 'dateOption.startedAt', 'desc');
    var add = useScheduleModal().add;
    return (React.createElement(XScrollableTable, { resizable: {
            additionalColumnWidth: 0
        }, id: 'CUSTOMER_RESERVATION_TABLE', dataSource: dataSource, pagination: getPaginateObject(), onRow: function (record) {
            return {
                onClick: function () {
                    return add({
                        id: record.id,
                        type: 'details',
                        schedule: record
                    });
                }
            };
        }, columns: getReservationColumns(false).filter(function (item) {
            if (item.dataIndex === 'type' || item.dataIndex === ['title']) {
                return false;
            }
            else {
                return true;
            }
        }) }));
};
export var TicketTable = function (props) {
    var _a, _b, _c, _d;
    var _e = React.useState(false), shownExpired = _e[0], setShownExpired = _e[1];
    var customerId = (_a = props.customer) === null || _a === void 0 ? void 0 : _a.id;
    var storeId = props.storeId;
    var columns = [
        {
            title: '구매일시',
            key: 'createdAt',
            width: 120,
            sorter: true,
            render: function (_, record) { return dayjs(record.createdAt).format(DATE_FORMAT); }
        },
        {
            title: props.type === 'prepaid' ? '선불액 상태' : '정기권 상태',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: function (_, record) { return record.getStatusToString(); }
        },
        {
            title: props.type === 'prepaid' ? '선불액명' : '정기권명',
            dataIndex: 'ticket',
            key: 'ticket',
            width: 150,
            render: function (_, record) {
                return "".concat(props.type === 'prepaid' ? '' : record.type === TICKET_TYPE.COUNT ? '[횟수권]' : '[기간권]', " ").concat(record.name);
            }
        },
        props.type === 'prepaid' && {
            title: '적립',
            key: 'ticketRemain',
            width: 100,
            render: function (_, record) { var _a; return KRWFomatToString({ value: (_a = record.data) === null || _a === void 0 ? void 0 : _a.total }); }
        },
        props.type === 'prepaid' && {
            title: '잔여',
            key: 'ticketRemain',
            width: 100,
            render: function (_, record) { var _a; return KRWFomatToString({ value: (_a = record.data) === null || _a === void 0 ? void 0 : _a.remain }); }
        },
        props.type === 'others' && {
            title: '잔여',
            key: 'ticketRemain',
            width: 100,
            render: function (_, record) { return record.getTicketDataToString(); }
        },
        {
            title: '만료(예정)일',
            key: 'expiredAt',
            sorter: true,
            width: 100,
            render: function (_, record) { return (record.expiredAt ? dayjs(record.expiredAt).format(DATE_FORMAT) : '무제한'); }
        }
    ].filter(Boolean);
    var DEFAULT_PARAMS = {
        page: 1,
        limit: 10,
        sortBy: 'createdAt',
        sortDir: 'desc',
        storeId: storeId,
        customerId: customerId ? Number(customerId) : undefined
    };
    var _f = TicketQuery.useTicketsByStoreId(__assign(__assign({}, DEFAULT_PARAMS), { status: shownExpired
            ? [TICKET_STATUS.ACTIVE, TICKET_STATUS.COMPLETE, TICKET_STATUS.EXPIRED]
            : [TICKET_STATUS.ACTIVE], type: props.type === 'others' ? [TICKET_TYPE.COUNT, TICKET_TYPE.TERM] : [TICKET_TYPE.CHARGING_MONEY] }), { enabled: !!storeId }), pagerResult = _f.pagerResult, getPaginateObject = _f.getPaginateObject, isLoading = _f.isLoading, setNewPagerOptions = _f.setNewPagerOptions, pagerOptions = _f.pagerOptions;
    var handleTableChange = function (pagination, filters, sorter) {
        setNewPagerOptions(__assign(__assign({}, DEFAULT_PARAMS), { sortBy: (sorter === null || sorter === void 0 ? void 0 : sorter.columnKey) || pagerOptions.sortBy || 'createdAt', sortDir: (sorter === null || sorter === void 0 ? void 0 : sorter.order) || pagerOptions.sortDir || 'desc', page: pagination.current, limit: pagination.pageSize }));
    };
    var isPrepaid = props.type === 'prepaid';
    var _g = React.useState(), selectedTicketId = _g[0], setSelectedTicketId = _g[1];
    var _h = useWindowSize(), isPC = _h.isPC, width = _h.width;
    var selectedTicket = (_b = pagerResult === null || pagerResult === void 0 ? void 0 : pagerResult.items) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.id === selectedTicketId; });
    return (React.createElement(React.Fragment, null,
        isPrepaid && (React.createElement(TextComponent, { type: "headline3", marginBottom: 5, children: "\uC794\uC5EC \uC120\uBD88\uC561 : ".concat(KRWFomatToString({ value: ((_d = (_c = props.customer) === null || _c === void 0 ? void 0 : _c.meta) === null || _d === void 0 ? void 0 : _d.prepaid) || 0 })) })),
        React.createElement(Row, { justify: "space-between", align: "middle", style: { marginBottom: '10px' } },
            React.createElement(Col, null,
                React.createElement(StyledCheckbox, { checked: shownExpired, onChange: function (e) {
                        setShownExpired(e.target.checked);
                        setNewPagerOptions(__assign(__assign({}, pagerOptions), { page: 1, status: e.target.checked
                                ? [TICKET_STATUS.ACTIVE, TICKET_STATUS.COMPLETE, TICKET_STATUS.EXPIRED]
                                : [TICKET_STATUS.ACTIVE] }));
                    } },
                    React.createElement(TextComponent, { type: "body1" }, isPrepaid ? '사용완료,기간만료된 선불액 보기' : '사용완료,기간만료된 정기권 보기'))),
            !props.hideControlButtons && (React.createElement(Col, null,
                React.createElement(ButtonWithPlusCircle, { onClick: function () {
                        return props.onClickOpenDrawer({
                            salesHistory: {
                                type: 'ticket',
                                data: { customerId: Number(customerId), customer: { id: Number(customerId) } }
                            }
                        });
                    } }, isPrepaid ? '선불액 판매' : '정기권 판매')))),
        React.createElement(XScrollableTable, { id: props.id, resizable: {
                additionalColumnWidth: 0
            }, bordered: false, columns: columns, onRow: function (record) {
                return {
                    onClick: function (event) {
                        try {
                            setSelectedTicketId(record.id);
                        }
                        catch (error) {
                            console.log('error', error);
                        }
                    }
                };
            }, rowKey: function (row) { return row.id; }, dataSource: pagerResult.items, pagination: getPaginateObject(), loading: isLoading, onChange: handleTableChange }),
        React.createElement(TicketPopup, { type: props.type === 'others' ? 'ticket' : 'prepaid', isPC: isPC, width: width, customer: props.customer, onClose: function () { return setSelectedTicketId(undefined); }, selectedTicket: selectedTicket })));
};
var StyledCheckbox = styled(Checkbox)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  & > .ant-checkbox {\n    margin-top: 5px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  & > .ant-checkbox {\n    margin-top: 5px;\n  }\n"])));
export var PrepaidPointTable = function (props) {
    var customerId = props.customer.id;
    var storeId = props.storeId;
    var columns = [
        {
            title: '일시',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: function (createdAt) { return dayjs(createdAt).format(DATE_FORMAT_INCLUDE_TIME); }
        },
        {
            title: '유형',
            dataIndex: 'type',
            key: 'type',
            render: function (_, row) { return row.typeToString; }
        },
        {
            title: '금액',
            dataIndex: 'change',
            key: 'change',
            render: function (_, row) { return row.changeString; }
        },
        {
            title: '소멸예정일',
            dataIndex: 'expiredAt',
            key: 'expiredAt',
            render: function (expiredAt) { return expiredAt && dayjs(expiredAt).format(DATE_FORMAT); }
        }
    ];
    var _a = PaymentHistoryQuery.usePrepaidHistoryPager({
        page: 1,
        limit: 10,
        storeId: storeId,
        customerId: customerId
    }, { enabled: !!storeId }), pagerResult = _a.pagerResult, getPaginateObject = _a.getPaginateObject, setNewPagerOptions = _a.setNewPagerOptions;
    var willExpiredPoint = CustomerQuery.useWillExpiredPoint({
        storeId: storeId,
        customerId: Number(customerId),
        type: 'prepaid'
    }, { enabled: !!storeId });
    return (React.createElement(React.Fragment, null,
        React.createElement(PupleBgRow, null,
            React.createElement(Col, { span: 8 },
                React.createElement(Row, { wrap: true },
                    React.createElement(TextComponent, { children: "\uC120\uBD88 \uCDA9\uC804\uC561 : ", color: COLORS.gray1, type: "body1", marginRight: 10 }),
                    React.createElement(TextComponent, { children: KRWFomatToString({ value: props.customer.prepaidPoint }), color: COLORS.primary1, type: "body1" }))),
            React.createElement(Col, null, willExpiredPoint.data ? (React.createElement(TextComponent, { children: "7\uC77C \uC774\uB0B4 \uC18C\uBA78 \uC608\uC815 \uCDA9\uC804\uC561: ".concat(KRWFomatToString({ value: willExpiredPoint.data })), color: COLORS.gray1, type: "body1" })) : (React.createElement("div", null)))),
        React.createElement(Marginer, { height: 20 }),
        React.createElement(TableComponent, { columns: columns, rowKey: function (row) { return row.id; }, dataSource: pagerResult.items, scroll: props.scroll, pagination: getPaginateObject(), loading: false })));
};
export var StyledAlertComponent = styled(AlertComponent)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 12px;\n  margin-bottom: 12px;\n"], ["\n  width: 100%;\n  margin-top: 12px;\n  margin-bottom: 12px;\n"])));
export var CustomerUsingCard = function (props) {
    var _a, _b, _c, _d, _e;
    var customer = props.customer;
    var items = [
        {
            key: 'createdAt',
            label: '최초 등록일',
            value: dayjs(customer.createdAt).format(DATE_FORMAT)
        },
        {
            key: 'lastVisitedAt',
            label: '최근 방문일',
            value: ((_a = customer.meta) === null || _a === void 0 ? void 0 : _a.lastVisitedAt) ? dayjs((_b = customer.meta) === null || _b === void 0 ? void 0 : _b.lastVisitedAt).format(DATE_FORMAT) : 0
        },
        { key: 'numberOfUsing', label: '방문 횟수', value: "".concat(((_c = customer.meta) === null || _c === void 0 ? void 0 : _c.numberOfUsing) || 0, "\uD68C") },
        { key: 'numberOfNoshow', label: '노쇼 횟수', value: "".concat(((_d = customer.meta) === null || _d === void 0 ? void 0 : _d.numberOfNoshow) || 0, "\uD68C") },
        { key: 'totalSales', label: '실 매출액', value: KRWFomatToString({ value: customer.totalSales || 0 }) },
        { key: 'customerPoint', label: '적립포인트', value: KRWFomatToString({ value: customer.customerPoint || 0 }) },
        { key: 'unpaid', label: '미수금', value: KRWFomatToString({ value: customer.unpaid || 0 }) },
        {
            key: 'averageSales',
            label: '평균 실매출액',
            value: KRWFomatToString({ value: floor(customer.totalSales / (((_e = customer === null || customer === void 0 ? void 0 : customer.meta) === null || _e === void 0 ? void 0 : _e.numberOfUsing) || 1)) || 0 })
        }
    ];
    var renderLabel = function (label, value) {
        return (React.createElement(Row, null,
            React.createElement(Col, { span: 10 }, label),
            React.createElement(Col, { span: 14 }, value)));
    };
    return (React.createElement(FullHeightCard, { title: "\uC774\uC6A9 \uC815\uBCF4", children: React.createElement("div", null, items.map(function (item) {
            if (item.key === 'hasTicketAmount') {
                return (React.createElement(UsingRow, { key: item.key },
                    renderLabel(React.createElement(FormItemLabel, { children: item.label }), React.createElement(TextComponent, { type: "headline3", children: "".concat(item.value, "\uC7A5"), color: COLORS.gray1 })),
                    renderLabel(undefined, React.createElement(AntPrimaryGhostButton, { children: "\uC815\uAE30\uAD8C \uAD6C\uB9E4", onClick: function () { return props.onClickDrawer({ type: 'ticket' }); } }))));
            }
            else {
                return (React.createElement(UsingRow, { key: item.key }, renderLabel(React.createElement(FormItemLabel, { children: item.label }), React.createElement(TextComponent, { type: "headline3", children: item.value, color: COLORS.gray1 }))));
            }
        })) }));
};
var getColumnKeys = function (type) {
    switch (type) {
        case 'all':
            return [
                'paidAt',
                'type',
                'location',
                'manager',
                // 'customerName',
                'ticket',
                'totalPrice',
                'deduction',
                'methods',
                'paidPrice',
                'createCustomerPoint',
                'memo'
                // 'management',
                // 'details',
                // 'qr'
            ];
        case 'usingHistory':
            return ['paidAt', 'manager', 'ticket', 'paidPrice', 'memo'];
        case 'unpaid':
            return ['paidAt', 'type', 'ticket', 'methods', 'paidPrice'];
    }
};
export var UsingRow = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
export var CustomerInformationCard = function (props) {
    var _a, _b, _c, _d;
    var customer = props.customer;
    var form = Form.useForm()[0];
    var update = CustomerQuery.useUpdateCustomerMutation();
    var _e = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _e[1];
    var handleBlur = function (name) { return __awaiter(void 0, void 0, void 0, function () {
        var fieldValue, nameToString, prevValue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fieldValue = form.getFieldValue(name);
                    nameToString = Lodash.isArray(name) ? name.map(function (item) { return item; }).join('.') : name;
                    prevValue = Lodash.get(customer, name);
                    if (!(fieldValue !== prevValue)) return [3 /*break*/, 2];
                    return [4 /*yield*/, update.mutateAsync(setShallow(customer, nameToString, fieldValue))];
                case 1:
                    _a.sent();
                    notification.success({ message: '고객 정보 수정 완료' });
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var leftFields = [
        { type: 'text', key: 'name', itemProps: { label: '이름', name: ['name'] }, inputProps: { onBlur: handleBlur } },
        {
            type: 'text',
            key: 'phone',
            itemProps: { label: '연락처', name: ['phone'], normalize: PhoneNumberNormalizer },
            inputProps: { onBlur: handleBlur }
        },
        {
            type: 'select',
            key: 'gender',
            itemProps: {
                label: '성별',
                name: ['gender']
            },
            inputProps: {
                options: [
                    { label: '여성', value: 'female' },
                    { label: '남성', value: 'male' }
                ],
                onBlur: handleBlur
            }
        },
        {
            type: 'datepicker',
            key: 'birth',
            itemProps: { label: '생일', name: ['birth'] },
            inputProps: { onBlur: handleBlur }
        },
        {
            type: 'address',
            key: 'address',
            itemProps: { label: '주소', name: ['address', 'address'] },
            inputProps: {
                withoutButton: true,
                value: form.getFieldValue(['address', 'address']),
                onComplete: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                    var address;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                address = form.getFieldValue('address');
                                return [4 /*yield*/, update.mutateAsync(setShallow(customer, 'address', __assign(__assign({}, address), value.address)))];
                            case 1:
                                _a.sent();
                                form.setFieldsValue({ address: { address: value.address.address } });
                                notification.success({ message: '고객 정보 수정 완료' });
                                forceUpdate();
                                return [2 /*return*/];
                        }
                    });
                }); }
            }
        },
        {
            type: 'text',
            key: 'detailAddress',
            itemProps: { label: '상세주소', name: ['address', 'detailAddress'] },
            inputProps: { onBlur: handleBlur }
        }
    ];
    var rightFields = [
        {
            type: 'select',
            key: 'managerId',
            itemProps: {
                label: '담당자',
                name: ['managerId']
            },
            inputProps: {
                onBlur: handleBlur,
                options: (_a = props.managers) === null || _a === void 0 ? void 0 : _a.map(function (manager) { return ({ label: manager.displayName, value: manager.id }); })
            }
        },
        {
            type: 'select',
            key: 'customerGroupId',
            itemProps: {
                label: '등급',
                name: ['customerGroupId']
            },
            inputProps: {
                onBlur: handleBlur,
                options: (_b = props.groups) === null || _b === void 0 ? void 0 : _b.map(function (group) { return ({ label: group.name, value: group.id }); })
            }
        },
        {
            type: 'select',
            key: 'customerTags',
            itemProps: {
                label: '태그',
                name: ['customerTags']
            },
            inputProps: {
                onBlur: handleBlur,
                mode: 'multiple',
                options: (_c = props.tags) === null || _c === void 0 ? void 0 : _c.map(function (tag) { return ({ label: tag.name, value: tag.id }); })
            }
        },
        {
            type: 'textarea',
            key: 'memo',
            itemProps: { label: '메모', name: ['memo'] },
            inputProps: {
                onBlur: handleBlur
            }
        }
    ];
    return (React.createElement(FullHeightCard, { title: "\uAE30\uBCF8 \uC815\uBCF4", children: React.createElement(Form, { form: form, initialValues: __assign(__assign({}, props.customer), { customerTags: ((_d = props.customer) === null || _d === void 0 ? void 0 : _d.customerTags) || [], birth: props.customer.birth ? dayjs(props.customer.birth) : undefined }), labelCol: { span: 6 }, wrapperCol: { span: 18 } },
            React.createElement(Row, { justify: "center", gutter: 8 },
                React.createElement(Col, { lg: { span: 12 }, md: { span: 24 } }, leftFields.map(function (field) {
                    return React.createElement(EditableContentFormItem, __assign({}, field, { key: field.key, form: form }));
                })),
                React.createElement(Col, { lg: { span: 12 }, md: { span: 24 } }, rightFields.map(function (field) {
                    return React.createElement(EditableContentFormItem, __assign({}, field, { key: field.key, form: form }));
                })))) }));
};
export var CustomerRelationTab = function (props) {
    var _a;
    var relations = (_a = props.customer) === null || _a === void 0 ? void 0 : _a.customerRelations;
    var columns = [
        {
            title: '고객명',
            dataIndex: 'customerName',
            key: 'customerName',
            render: function (_, row) { return row.getCustomerName(props.customer.id); }
        },
        {
            title: '관계명',
            dataIndex: 'relationName',
            key: 'relationName',
            render: function (_, row) { return row.getRelationName(props.customer.id); }
        },
        {
            title: '공유',
            dataIndex: 'shareOption',
            key: 'shareOption',
            render: function (_, row) { return row.shareOptionString; }
        },
        {
            title: '메모',
            dataIndex: 'memo',
            key: 'memo'
            // render: (expiredAt) => expiredAt && dayjs(expiredAt).format(DATE_FORMAT)
        }
    ];
    var _b = React.useState(), customerRelation = _b[0], setCustomerRelation = _b[1];
    var _c = React.useState(false), openMergeModal = _c[0], setOpenMergeModal = _c[1];
    var addRelation = function () {
        setCustomerRelation({
            mainCustomerId: Number(props.customer.id),
            mainCustomer: props.customer
        });
    };
    var create = CustomerRelationQuery.useCreateCustomerRelations(props.storeId);
    var update = CustomerRelationQuery.useUpdateCustomerRelations(props.storeId, customerRelation === null || customerRelation === void 0 ? void 0 : customerRelation.id);
    var deleteFunc = CustomerRelationQuery.useDeleteCustomerRelations(props.storeId, customerRelation === null || customerRelation === void 0 ? void 0 : customerRelation.id);
    var renderHeaderButtons = function () {
        return (React.createElement(Layout.FluidInline, { gutter: 8, justify: "end", style: { marginBottom: '10px' } },
            React.createElement(StyledHeaderButton, { children: "\uACE0\uAC1D \uBCD1\uD569\uD558\uAE30", type: "primary", onClick: function () { return setOpenMergeModal(true); } }),
            React.createElement(StyledHeaderButton, { children: "\uACE0\uAC1D \uC5F0\uACB0\uD558\uAE30", type: "primary", onClick: addRelation })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomerMergeModal, { open: openMergeModal, onClose: function () { return setOpenMergeModal(false); }, customer: props.customer, storeId: props.storeId }),
        React.createElement(CustomerRelationModal, { storeId: props.storeId, customerRelation: customerRelation, onClose: function () {
                setCustomerRelation(null);
            }, onDelete: function (id) { return __awaiter(void 0, void 0, void 0, function () {
                var dialog;
                return __generator(this, function (_a) {
                    dialog = modal.confirm({
                        centered: true,
                        zIndex: 9999,
                        cancelText: '취소',
                        onCancel: function () { return dialog.destroy(); },
                        content: '정말 삭제하시겠어요?',
                        okText: '삭제',
                        onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var err_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, 3, 4]);
                                        return [4 /*yield*/, deleteFunc.mutateAsync({ id: id })];
                                    case 1:
                                        _a.sent();
                                        notification.info({ message: '삭제 완료' });
                                        return [3 /*break*/, 4];
                                    case 2:
                                        err_1 = _a.sent();
                                        notification.error({ message: err_1.message });
                                        return [3 /*break*/, 4];
                                    case 3:
                                        setCustomerRelation(null);
                                        return [7 /*endfinally*/];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); }
                    });
                    return [2 /*return*/];
                });
            }); }, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                var err_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 5, , 6]);
                            if (!values.id) return [3 /*break*/, 2];
                            return [4 /*yield*/, update.mutateAsync(values)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, create.mutateAsync(values)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            setCustomerRelation(null);
                            notification.open({ message: '고객이 연결되었습니다.', type: 'success' });
                            return [3 /*break*/, 6];
                        case 5:
                            err_2 = _a.sent();
                            notification.open({ message: err_2.message, type: 'error' });
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            }); } }),
        isEmpty(relations) ? (React.createElement(React.Fragment, null,
            renderHeaderButtons(),
            React.createElement(Layout.FluidStack, { style: {
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                } },
                React.createElement("img", { src: IMAGES.customer_relation_empty_button, style: { cursor: 'pointer' }, onClick: addRelation }),
                React.createElement(TextComponent, { children: "\uC6B0\uCE21 \uC0C1\uB2E8\uC758 [\uACE0\uAC1D \uC5F0\uACB0\uD558\uAE30] \uBC84\uD2BC\uC744 \uD074\uB9AD\uD574" }),
                React.createElement(TextComponent, { children: "\uACE0\uAC1D\uC744 \uC5F0\uACB0\uD558\uACE0 \uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C\uC744 \uACF5\uC720\uD558\uC138\uC694" })))) : (React.createElement(React.Fragment, null,
            renderHeaderButtons(),
            React.createElement(XScrollableTable, { columns: columns, bordered: false, rowKey: function (row) { return row.id; }, onRow: function (record) {
                    return {
                        onClick: function (event) {
                            try {
                                setCustomerRelation(record);
                            }
                            catch (error) {
                                console.log('error', error);
                            }
                        }
                    };
                }, dataSource: relations, loading: false, id: "CUSTOMER_RELATION_TABLE", resizable: {
                    additionalColumnWidth: 0
                } })))));
};
export var PicturesTab = function (props) {
    var _a, _b, _c, _d;
    var pictureQuery = StorePictureQuery.useCustomerPictures(props.storeId, { customerId: Number((_a = props.customer) === null || _a === void 0 ? void 0 : _a.id) }, { enabled: !!((_b = props.customer) === null || _b === void 0 ? void 0 : _b.id) });
    var loader = useEntireLoader();
    var _e = React.useState(), current = _e[0], setCurrent = _e[1];
    var hasCurrent = current >= 0;
    var salesHistoryId = hasCurrent && pictureQuery.data && ((_c = pictureQuery.data[current]) === null || _c === void 0 ? void 0 : _c.salesHistoryId);
    var salesQuery = PaymentHistoryQuery.useGetSalesDetailById({
        storeId: props.storeId,
        salesHistoryId: salesHistoryId
    }, {
        enabled: !!salesHistoryId,
        staleTime: 60 * 1000
    });
    var saleModal = useSaleModal();
    var pictureWidth = props.isPC ? 140 : 97;
    var _f = useMeasure(), ref = _f[0], width = _f[1].width;
    var columnLevel = floor(width / pictureWidth);
    var columns = columnLevel > 8 ? 8 : columnLevel;
    var storeId = props.customer.storeId;
    var postSalesPictures = StorePictureQuery.useCreateStorePictures(storeId, {
        customerId: Number((_d = props.customer) === null || _d === void 0 ? void 0 : _d.id)
    });
    var deletePicture = StorePictureQuery.useDeleteStorePictures(storeId);
    var handleUpload = debounce(function (info) { return __awaiter(void 0, void 0, void 0, function () {
        var processedFiles, error_1, pictures;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    processedFiles = [];
                    if (((_a = info.fileList) === null || _a === void 0 ? void 0 : _a.length) === 0 || !info.fileList) {
                        return [2 /*return*/, notification.error({ message: '사진을 선택해주세요.' })];
                    }
                    if (((_b = info.fileList) === null || _b === void 0 ? void 0 : _b.length) > 8) {
                        return [2 /*return*/, notification.error({ message: '사진은 8장 이하로 올려주세요.' })];
                    }
                    loader.show();
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Promise.all(info.fileList.map(function (_a) {
                            var type = _a.type, originFileObj = _a.originFileObj;
                            return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!['image/heic', 'image/heif'].includes(type)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, convertHEICToJPG(originFileObj)];
                                        case 1: return [2 /*return*/, _b.sent()]; // HEIC -> JPG 변환
                                        case 2: return [2 /*return*/, originFileObj]; // HEIC가 아닌 파일은 그대로 반환
                                    }
                                });
                            });
                        }))];
                case 2:
                    processedFiles = _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    loader.hide();
                    return [2 /*return*/, alert('HEIC 파일 변환 중 오류가 발생했습니다.')];
                case 4: return [4 /*yield*/, S3Client.uploadImagesToS3([], processedFiles, {
                        dirName: "store_sales_pictures/".concat(storeId)
                    })];
                case 5:
                    pictures = _c.sent();
                    return [4 /*yield*/, postSalesPictures.mutateAsync(pictures.map(function (item) {
                            return {
                                url: item,
                                customerId: props.customer.id,
                                storeId: storeId
                            };
                        }))];
                case 6:
                    _c.sent();
                    loader.hide();
                    return [2 /*return*/];
            }
        });
    }); }, 1000);
    var handleDelete = function (index) { return __awaiter(void 0, void 0, void 0, function () {
        var picture, content;
        return __generator(this, function (_a) {
            picture = pictureQuery.data && pictureQuery.data[index];
            if (picture) {
                content = picture.salesHistoryId
                    ? "\uC0AD\uC81C\uD558\uC2E4 \uACBD\uC6B0 \uBCF5\uAD6C\uD558\uC2E4 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4."
                    : "\uC0AD\uC81C\uD558\uC2E4 \uACBD\uC6B0 \uBCF5\uAD6C\uD558\uC2E4 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.";
                AntModalFunc({
                    type: 'confirm',
                    zIndex: 9999,
                    content: content,
                    okText: '삭제',
                    onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, deletePicture.mutateAsync({ id: picture.id })];
                                case 1:
                                    _a.sent();
                                    setCurrent(undefined);
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    okButtonProps: {
                        danger: true,
                        type: 'primary'
                    }
                });
            }
            return [2 /*return*/];
        });
    }); };
    var handleSaleClick = function () {
        var _a;
        saleModal.add({
            fixedCustomerId: true,
            id: (_a = salesQuery.data) === null || _a === void 0 ? void 0 : _a.id,
            type: salesQuery.data.type,
            data: salesQuery.data,
            controllerKey: SALES_CONTROLLER_KEYS.SELECT_MENU
        });
    };
    return (React.createElement(Layout.Columns, { gutter: 8, ref: ref, columns: columns },
        React.createElement(UploadButton, { fileList: [], width: pictureWidth, maxCount: 8, accept: isPlussharpMobileAppAndAndroid() ? '.jpg,.jpeg,.png,.heic,.heif,.exe' : 'image/jpg,image/jpeg,image/png,image/heic,image/heif', multiple: true, onChange: handleUpload }),
        React.createElement(ZIndexer, null, function (_a) {
            var _b, _c;
            var zIndex = _a.zIndex;
            return (React.createElement(Image.PreviewGroup, { key: "".concat(pictureQuery === null || pictureQuery === void 0 ? void 0 : pictureQuery.dataUpdatedAt, "_").concat((_b = pictureQuery === null || pictureQuery === void 0 ? void 0 : pictureQuery.data) === null || _b === void 0 ? void 0 : _b.length), preview: {
                    zIndex: zIndex,
                    visible: hasCurrent,
                    onChange: function (current) { return setCurrent(current); },
                    onVisibleChange: function (value, prevValue, current) {
                        if (value) {
                            setCurrent(current);
                        }
                        else {
                            setCurrent(undefined);
                        }
                    },
                    countRender: function (current, total) {
                        var _a;
                        return (React.createElement(Flex, { justify: "center", vertical: true },
                            salesQuery.data && (React.createElement(MetaWrapper, null,
                                React.createElement(FluidRow, { justify: "start" },
                                    React.createElement(TextComponent, { color: COLORS.white, type: "subtitle2", children: dayjs(salesQuery.data.paidAt).format(DATE_FORMAT_INCLUDE_TIME) })),
                                React.createElement(Row, { align: "middle", gutter: 16, justify: "start" },
                                    React.createElement(Col, null,
                                        React.createElement(TextComponent, { type: "caption1", children: "\uACE0\uAC1D\uBA85", color: COLORS.white })),
                                    React.createElement(Col, null,
                                        React.createElement(TextComponent, { type: "subtitle3", children: (_a = salesQuery.data.customer) === null || _a === void 0 ? void 0 : _a.name, color: COLORS.white }))),
                                React.createElement(Row, { align: "middle", gutter: 16, justify: "start", wrap: false },
                                    React.createElement(Col, null,
                                        React.createElement(TextComponent, { type: "caption1", children: "\uC0C1\uD488\uBA85", color: COLORS.white })),
                                    React.createElement(Col, null,
                                        React.createElement(TextComponent, { type: "subtitle3", children: salesQuery.data.saleListString, color: COLORS.white }))),
                                React.createElement(FluidRow, { justify: "end" },
                                    React.createElement(AntButton, { type: "link", size: "small", onClick: handleSaleClick, style: { color: COLORS.primary3 } }, '매출내역 조회 >')))),
                            React.createElement(FluidRow, { justify: "center" },
                                React.createElement(TextComponent, { marginTop: 32, color: COLORS.white, type: "subtitle2", children: "".concat(current, "/").concat(total) }))));
                    },
                    toolbarRender: function (_, info) {
                        return (React.createElement("div", null,
                            React.createElement(ToolbarWrapper, null,
                                React.createElement(Col, null,
                                    React.createElement(Icon, { name: "rotate", size: 18, onClick: info.actions.onRotateLeft })),
                                React.createElement(Col, null,
                                    React.createElement(Icon, { name: "code", size: 18, onClick: info.actions.onFlipX })),
                                React.createElement(Col, null,
                                    React.createElement(Icon, { name: "zoomIn", size: 18, onClick: info.actions.onZoomIn })),
                                React.createElement(Col, null,
                                    React.createElement(Icon, { name: "zoomOut", size: 18, onClick: info.actions.onZoomOut })),
                                React.createElement(Col, null,
                                    React.createElement(Icon, { name: "trash-2", size: 18, onClick: function () { return handleDelete(info.current); } })))));
                    }
                } }, (_c = pictureQuery.data) === null || _c === void 0 ? void 0 : _c.map(function (item, index) {
                return (React.createElement(Layout.Column, { key: "".concat(item.id) },
                    React.createElement(ImageBG, { style: { width: "".concat(pictureWidth, "px"), height: "".concat(pictureWidth, "px") } },
                        React.createElement(Image, { src: item.url, onClick: function () { return setCurrent(index); }, style: { width: "100%", height: "".concat(pictureWidth, "px"), zIndex: 9999 } }),
                        React.createElement(ImageDateWrapper, null,
                            React.createElement(TextComponent, { type: "caption2", color: COLORS.white, marginLeft: 4, children: dayjs(item.createdAt).format('YYYY-MM-DD a hh:mm') })))));
            })));
        })));
};
var MetaWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  width: 360px;\n  padding: 8px 12px 4px 12px;\n\n  flex-direction: column;\n  align-items: start;\n  border-radius: 6px;\n  background: rgba(0, 0, 0, 0.1);\n\n  /* BoxShadowSecondary */\n  box-shadow:\n    0px 9px 28px 8px rgba(0, 0, 0, 0.05),\n    0px 3px 6px -4px rgba(0, 0, 0, 0.12),\n    0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n"], ["\n  display: flex;\n  width: 360px;\n  padding: 8px 12px 4px 12px;\n\n  flex-direction: column;\n  align-items: start;\n  border-radius: 6px;\n  background: rgba(0, 0, 0, 0.1);\n\n  /* BoxShadowSecondary */\n  box-shadow:\n    0px 9px 28px 8px rgba(0, 0, 0, 0.05),\n    0px 3px 6px -4px rgba(0, 0, 0, 0.12),\n    0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n"])));
var ToolbarWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  padding: 0px 24px;\n  justify-content: center;\n  align-items: flex-end;\n  gap: 12px;\n  border-radius: 99px;\n  background: rgba(0, 0, 0, 0.1);\n\n  & > div {\n    padding: 12px;\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  padding: 0px 24px;\n  justify-content: center;\n  align-items: flex-end;\n  gap: 12px;\n  border-radius: 99px;\n  background: rgba(0, 0, 0, 0.1);\n\n  & > div {\n    padding: 12px;\n    cursor: pointer;\n  }\n"])));
var ImageDateWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0px;\n  width: 100%;\n  background-color: ", ";\n"], ["\n  position: absolute;\n  bottom: 0px;\n  width: 100%;\n  background-color: ", ";\n"])), convertHexToRgbA(COLORS.black, 0.5));
var ImageBG = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  border-radius: 2px;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  position: relative;\n"], ["\n  border-radius: 2px;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  position: relative;\n"])));
export var EditableContentFormItem = function (props) {
    var content = props.content, type = props.type, inputProps = props.inputProps, itemProps = props.itemProps, form = props.form;
    var value = form.getFieldValue(itemProps.name);
    return (React.createElement(FullWidthFormItem, __assign({}, itemProps),
        React.createElement(EditableContent, __assign({}, props))));
};
export var EditableContent = function (props) {
    var content = props.content, type = props.type, inputProps = props.inputProps, itemProps = props.itemProps, form = props.form;
    //   const value = form.getFieldValue(itemProps.name);
    var _a = React.useState(false), editable = _a[0], setEditable = _a[1];
    var handleToggle = function () {
        setEditable(!editable);
    };
    var onChange = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        props.onChange.apply(props, args);
    };
    var renderValue = function (value) {
        var _a;
        if (type === 'datepicker') {
            return dayjs(value).format(DATE_FORMAT);
        }
        else if (type === 'select') {
            if (inputProps.mode === 'multiple') {
                return (value || [])
                    .map(function (item) {
                    var _a;
                    var option = (_a = inputProps.options) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.value === item; });
                    return option ? option.label : value;
                })
                    .join(', ');
            }
            else {
                var option = (_a = inputProps.options) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.value === value; });
                return option ? option.label : value;
            }
        }
        else if (type === 'address') {
            return value;
        }
        else {
            return value;
        }
    };
    var handleComplete = function () {
        setEditable(false);
        if (inputProps.onBlur) {
            inputProps.onBlur(itemProps.name);
        }
    };
    return (React.createElement(Row, null,
        React.createElement(HiddenCol, { span: 20, hidden: !editable }, InputByType({
            type: type,
            inputProps: __assign(__assign({}, inputProps), { value: props.value, onChange: onChange, onBlur: handleComplete, onPressEnter: type === 'text' && handleComplete })
        })),
        content ? (content) : (React.createElement(HiddenCol, { span: 24, hidden: editable },
            React.createElement(Col, { span: 20 },
                React.createElement(TextComponent, { type: "headline3", children: props.value ? renderValue(props.value) : '' })),
            React.createElement(Col, { span: 4 }, !editable && React.createElement(Icon, { name: "edit", onClick: handleToggle }))))));
};
export var HiddenCol = styled(Col)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: ", ";\n"], ["\n  display: ", ";\n"])), function (props) { return (props.hidden ? 'none' : 'flex'); });
// export const EditableContent = (props: any) => {
//   const { content, type, inputProps, itemProps, form } = props;
//   const value = form.getFieldValue(itemProps.name);
//   const [editable, setEditable] = React.useState(false);
//   const handleToggle = () => {
//     setEditable(!editable);
//   };
//   return editable ? (
//     <Col span={20}>
//       {InputByType({ type, inputProps: { ...inputProps, value: props.value, onBlur: handleToggle } })}
//     </Col>
//   ) : content ? (
//     content
//   ) : (
//     <Row>
//       <Col span={16}>
//         <TextComponent type="subtitle2" children={value} />
//       </Col>
//       <Col span={4}>{!editable && <Icon name="edit" onClick={handleToggle} />}</Col>
//     </Row>
//   );
// };
export var FullWidthFormItem = styled(StyledFormItem)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var Label = styled(TextComponent)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  width: 60px;\n"], ["\n  width: 60px;\n"])));
export var Grayboarder = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n"], ["\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n"])));
export var CustomerNameCardWrapper = styled(Grayboarder)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  flex-direction: column;\n"], ["\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  flex-direction: column;\n"])));
export var CustomerProfileCircle = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  width: 125px;\n  height: 125px;\n  border: solid 1px #979797;\n  background-color: #d8d8d8;\n  border-radius: 50%;\n"], ["\n  width: 125px;\n  height: 125px;\n  border: solid 1px #979797;\n  background-color: #d8d8d8;\n  border-radius: 50%;\n"])));
export var FullHeightCard = styled(CardWithSubmit)(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
export var BackButton = styled(Button)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  margin-bottom: 30px;\n\n  & svg {\n    margin-right: 8px;\n  }\n"], ["\n  margin-bottom: 30px;\n\n  & svg {\n    margin-right: 8px;\n  }\n"])));
export var InformationCard = function (props) {
    return (React.createElement(InformationCardWrapper, null,
        React.createElement(Row, { justify: "space-between" },
            React.createElement(TextComponent, { children: props.title, type: "headline3", marginBottom: 20 }),
            props.rightAction),
        props.items.map(function (item, index) {
            var Component = item.label === '메모' ? PreLineText : TextComponent;
            return (React.createElement(Row, { key: item.key || index, wrap: false },
                React.createElement(Col, null,
                    React.createElement(StyledLabel, { children: item.label })),
                React.createElement(Col, { flex: 1 },
                    React.createElement(Component, { type: "subtitle2", children: item.data, color: COLORS.black }))));
        })));
};
var InformationCardWrapper = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  & .ant-col {\n    margin: 0px;\n  }\n"], ["\n  & .ant-col {\n    margin: 0px;\n  }\n"])));
var StyledLabel = styled(TextComponent).attrs({
    type: 'subtitle2',
    color: COLORS.gray2,
    marginBottom: 5,
    marginRight: 10
})(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  width: 80px;\n"], ["\n  width: 80px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26;
